
function Footer() {
  return (
    <div>
      <footer
        style={{ backgroundColor: "#525880" }}
        className="section-footer "
      >
        <div className="container">
          <section className="footer-top padding-y-lg text-white">
            <div className="row">
              <aside className="col-md mr-4 col-6">
                <img height={100} src="/assets/images/logos/logow.png" />
                <ul className="list-unstyled mt-2">
                  <p style={{color:"white", fontSize:"10"}}>
                  Alahdeen connects buyers and sellers, providing a complete package from online selling, managing inventory, and warehousing to logistics, and Products.
                  </p>
                </ul>
              </aside>
              <aside className="col-md col-6 ml-4 mt-4">
                <h6 className="title"></h6>
                <ul className="list-unstyled">
              
                

                  <li>
                    {" "}
                    <a href="/services/aboutus.html">About Us</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/contactus.html">Contact Us</a>
                  </li>
                  {/* <li>
                    {" "}
                    <a href="/services/customercare.html">Customer Care</a> 
                  </li> */}
                </ul>
              </aside>

              <aside className="col-md col-6">
                <h6 className="mb-4">Our Services</h6>
                <ul className="list-unstyled">
                  <li>
                    {" "}
                    <a href="/services/logistics.html"> Logistics </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/warehouse.html"> Warehouse </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/ewallet.html"> E-Wallet </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/office.html"> Office Management </a>
                  </li>
                </ul>
              </aside>
              <aside className="col-md col-6">
                <ul className="list-unstyled">
                  <li style={{ marginBottom: "15px" }}>
                    <br />
                  </li>

                  <li>
                    <a href="/services/adon.html"> Advertisement </a>
                  </li>
                  <li>
                    <a href="/services/setup.html">Setup a Business</a>
                  </li>
                  <li>
                    <a href="/services/inventory.html">Inventory</a>
                  </li>
                  <li> <a href="/services/taxation.html">Taxation</a>
                  </li>
                </ul>
              </aside>
              <aside className="col-md">
                <h6 className="mb-3">Social</h6>
                <ul className="list-unstyled">
                  <li>
                    <a href="https://www.facebook.com/Alahdeen-106216935505534">
                      {" "}
                      <i className="fab fa-facebook"></i> Facebook{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/alahdeenb2b">
                      {" "}
                      <i className="fab fa-twitter"></i> Twitter{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/alahdeenb2b/">
                      {" "}
                      <i className="fab fa-instagram"></i> Instagram{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCQG0ra_23YYp6T8WNJAQZNw">
                      {" "}
                      <i className="fab fa-youtube"></i> Youtube{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/alahdeen">
                      {" "}
                      <i className="fab fa-linkedin"></i> Linkedin{" "}
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
          </section>


          <section className="footer-bottom text-center">
            <p style={{ color: "white" }} >
              
              &copy; 2024 Alahdeen , All rights reserved.
            </p>

          </section>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
