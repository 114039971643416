import { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

function LocationLogger() {
  const [metaData, setMetaData] = useState([]);
  

  const get_meta = async () => {
    console.log("meta_url",window.location.href)
    const data = new FormData();
    data.append("url",window.location.href); // Directly use current URL
    try {
      const response = await axios.post("/pro/seo/get_meta.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      setMetaData(response.data); // Set the fetched metadata
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };

  useEffect(() => {
    get_meta(); // Fetch metadata on component mount
  }, []);

  return (
    <>
      {metaData.length == 0 ? <></>: metaData[0].msg=="pass"?<>
        
        <Helmet>
          <title>{metaData[0].t}</title>
          <meta name="description" content={metaData[0].d} />
          <meta name="keywords" content={metaData[0].k} />
          <meta property="og:title" content={metaData[0].t} />
          <meta property="og:description" content={metaData[0].d} />
          <meta
            property="og:image"
            content="https://alahdeen.com/assets/images/logos/logo.png"
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="business.business" />
          <meta property="og:site_name" content="Alahdeen" />
        </Helmet>
      </>:<>
      <Helmet>
          <title>Alahdeen | Best B2B Marketplace</title>
          <meta
            name="description"
            content="Alahdeen connects buyers and sellers, providing a complete package from online selling, managing inventory, and warehousing to logistics, and Products."
          />
          <meta name="keywords" content="Best b2b marketplace Pakistan" />
          <meta property="og:title" content="Alahdeen | B2B Marketplace" />
          <meta
            property="og:description"
            content="Alahdeen connects buyers and sellers, providing a complete package from online selling, managing inventory, and warehousing to logistics, and Products"
          />
          <meta
            property="og:image"
            content="https://alahdeen.com/assets/images/logos/logo.png"
          />
          <meta property="og:url" content="https://www.alahdeen.com/" />
          <meta property="og:type" content="business.business" />
          <meta property="og:site_name" content="Alahdeen" />
        </Helmet>
        </>}

    </>
  );
}

export default LocationLogger;
